<template>
  <v-navigation-drawer width="600" right top fixed temporary v-model="visibility">
    <div class="cart-wrapper" :class="{'on-mobile':$vuetify.breakpoint.mobile}">
      <v-row align="center" no-gutters class="px-2" justify="space-between">
        <div class="d-flex">
          <v-btn icon plain color="green" @click="$emit('close')">
            <v-icon>
              mdi-keyboard-backspace
            </v-icon></v-btn
          >
          <v-card-title class="py-0">{{ $t("market.cart") }}</v-card-title>
        </div>
        <v-btn
          plain
          color="error"
          @click="onClearCart"
          :disabled="loading || onLoadingDeleteItem"
          v-if="products.length"
        >
          <v-icon left>
            mdi-delete-outline
          </v-icon>
          {{ $t("btn.clear") }}
        </v-btn>
      </v-row>
      <v-fade-transition mode="out-in" hide-on-leave>
        <v-sheet class="px-2 items-wrapper" height="80vh" v-if="!loading">
          <CartItems
            :products="products"
            v-if="products.length"
            :onLoadingDeleteItem="onLoadingDeleteItem"
          />
          <v-row v-else justify="center" align="center" class="h-100">

              <span class="font-weight-medium text-h6">{{ $t("market.empty_cart") }}</span>
          </v-row>
        </v-sheet>
      </v-fade-transition>
      <v-fade-transition mode="in-out" hide-on-leave>
        <v-sheet class="px-2 items-wrapper" height="70vh" width="100%" color="white" v-if="loading">
          <v-row justify="center" align="center" class="h-100">
            <v-progress-circular indeterminate color="green" />
          </v-row>
        </v-sheet>
      </v-fade-transition>
      <v-divider />
      <v-sheet class="pa-2" height="auto">
        <CartForm :products="products" :resetForm="resetForm" :loading="loading" />
      </v-sheet>
    </div>
  </v-navigation-drawer>
</template>

<script>
import _ from "lodash";
import notifications from "@/mixins/notifications";
import loader from "@/mixins/loader";
import CartItems from "./CartItems.vue";
import CartForm from "./CartForm.vue";
import EventBus from "@/events/EventBus";
import crmForms from "@/services/request/crm/crmForms";

export default {
  name: "CartComponet",
  props: {
    visible: {
      require: true
    },
    cartUUID: {
      require: true
    }
  },
  data: () => ({
    onLoadingDeleteItem: false,
    cart: [],
    products: [],
    loading: false,
    cartData: [],
    resetForm: false
  }),
  mounted() {
    EventBus.$on("on-order", data => {
      this.onSubmitOrder(data);
    });
    EventBus.$on("on-delete-from-cart", uuid => {
      this.deleteItemFromCart(uuid);
    });
    EventBus.$on("on-modified-item-cart", data => {
      if (!this.products.length) return;
      this.onLoadingDeleteItem = true;
      this.updateCartItem(data);
    });
  },
  methods: {
    // eslint-disable-next-line
    async onSubmitOrder({ name, phone_number }) {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phone", phone_number);
        formData.append("utm_content",this.$cookies.get("utm_content"));
        formData.append("utm_campaign",this.$cookies.get("utm_campaign"));
        formData.append("utm_source",this.$cookies.get("utm_source"));
        formData.append("utm_medium",this.$cookies.get("utm_medium"));
        formData.append("utm_term",this.$cookies.get("utm_term"));

         const res = await crmForms.submitOrder(this.cartUUID.uuid_cart, formData);
        await this.getProductCartitems();
         
        this.setSuccessNotification(this.$t("market.on_submit"));
        this.resetForm = true;
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async getProductCartitems() {
      try {
         
        if (!this.cartUUID?.uuid_cart) return;
        this.loading = true;
        this.products = (await crmForms.getCartProductList(this.cartUUID.uuid_cart)) || [];
         
        EventBus.$emit("totalItemsInCart", this.products.length);
         
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async onClearCart() {
      try {
        this.loading = true;
        await crmForms.clearCart(this.cartUUID?.uuid_cart);
        await this.getProductCartitems();
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async deleteItemFromCart(uuid) {
      try {
        this.onLoadingDeleteItem = true;
        await crmForms.deleteItemFromCart(uuid);
        await this.getProductCartitems();
      } catch (e) {
        console.log(e)
      } finally {
        this.onLoadingDeleteItem = false;
      }
    },
    // eslint-disable-next-line
    updateCartItem: _.debounce(function({
      // eslint-disable-next-line
      uuid_form_item,
      amount,
      // eslint-disable-next-line
      uuid_cart,
      // eslint-disable-next-line
      uuid_form_item_option
    }) {
      try {
        this.onLoadingDeleteItem = true;

        const formData = new FormData();
        formData.append("uuid_cart", uuid_cart);
        formData.append("amount", amount);
        formData.append("uuid_form_item", uuid_form_item);
        // eslint-disable-next-line
        if (uuid_form_item_option) {
          formData.append("uuid_form_item_option", uuid_form_item_option);
        }
        crmForms.updateAmountItemInCart(formData);
        // await crmForms.addItemInCart(formData);
        // await this.getProductCartitems();
      } catch (e) {
        console.log(e)
      } finally {
        this.onLoadingDeleteItem = false;
        // this.loading = false;
      }
    }, 700)
  },
  computed: {
    getCartUUID() {
      const formStorageData = JSON.parse(localStorage.getItem("form-data"));
      return formStorageData?.uuid_cart;
    },
    visibility: {
      get() {
        this.getProductCartitems();
        return this.visible;
      },
      set(visible) {
        if (!visible) {
          return this.$emit("close");
        }
      }
    }
  },
  components: {
    CartItems,
    CartForm
  },

  mixins: [notifications, loader]
};
</script>

<style lang="scss" scoped>
.cart-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  &.on-mobile{
    height: 100vh;
    height: 100%;
    
  }
}
.items-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
}
.empty-image {
  position: absolute;
  width: 80%;
  opacity: 0.05;
}
</style>
