<template>
  <div>
    <v-row class="pa-2 pb-0">
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="cart.name"
          @paste="validationForName($event)"
          hide-details="auto"
          outlined
          dense
          :label="$t('form.name')"
          :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
          @input="noEngText($event, false)"
          :error-messages="NameErrors"
          v-on:keypress="validationForName($event)"
          :maxLength="20"
          class="br-10"
          :disabled="!products.length"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="cart.phone_number"
          hide-details="auto"
          outlined
          dense
          class="br-10"
          :label="$t('form.phone_number')"
          :error-messages="PhoneErrors"
          prefix="+38"
          v-on:keypress="validationForPhoneNumber($event)"
          @paste="validationForPhoneNumber($event)"
          :maxLength="14"
          :disabled="!products.length"
        />
      </v-col>
    </v-row>
    <v-row class="px-2">
      <v-col cols="12">
        <v-btn
          class="success-bg white--text px-2 my-2 br-10 text-transform-none"
          block
          @click="onOrder"
          :disabled="!products.length || loading"
          :loading="loading"
        >
          {{ `${$t("btn.order")} ${getTotalPrice} ${$t("paymentPlan.uah")}` }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  noEngText,
  noEngTextPasted,
  onlyNumber,
  validationForName, validationForPhoneNumber, validationForTitle
} from "@/mixins/helpers";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import phoneMask from "@/validators/phoneMask";
import noEngTextValidator from "@/validators/noEngText";
import EventBus from "@/events/EventBus";

export default {
  props: {
    products: {
      required: true
    },
    resetForm: {
      required: true
    },
    loading: {
      required: true
    }
  },
  data: () => ({
    cart: {},
    onEngText: true
  }),
  methods: {
    validationForPhoneNumber,
    validationForTitle,
    validationForName,
    noEngText,
    onresetForm() {
      this.cart = {};
      this.$v.$reset();
    },
    showImgDialog(product) {
      this.imgpath = product.product_details.path_img;
      this.imgDialog = true;
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    onOrder() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      EventBus.$emit("on-order", this.cart);
    }
  },
  computed: {
    getTotalPrice() {
      let price = 0;
      if (this.products.length) {
        this.products.forEach(product => {
          const priceToNumber = product.price || 0;
          const priceWithDiscount = Math.floor(price * ((100 - product.discount) / 100));
          if (Number(product.discount) > 0) {
            price += product.amount * priceWithDiscount;
          } else {
            price += product.amount * priceToNumber;
          }
        });
      }
      return price.toFixed(2);
    },
    NameErrors() {
      const errors = [];
      if (!this.$v.cart.name.$dirty) {
        return errors;
      }
      if (!this.$v.cart.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.cart.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    PhoneErrors() {
      const errors = [];
      if (!this.$v.cart.phone_number.$dirty) {
        return errors;
      }
      if (!this.$v.cart.phone_number.phoneMask) {
        errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.cart.phone_number.required &&
        errors.push(this.$t("form.errors.PhoneNumberRequired"));
      return errors;
    }
  },
  watch: {
    resetForm: {
      deep: true,
      handler(isReset) {
        if (isReset) {
          this.onresetForm();
        }
      }
    }
  },
  validations: {
    cart: {
      name: { required, noEngTextValidator },
      phone_number: {
        phoneMask,
        required
      }
    }
  },
  mixins: [validationMixin]
};
</script>

<style></style>
