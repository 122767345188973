<template>
  <v-hover v-slot="{ hover }">
    <v-card
      @click="onClickDetails"
      class="mx-auto my-5 cursor-pointer"
      :elevation="hover ? 3 : 2"
      :disabled="loading"
      width="350"
      :ripple="false"
    >
      <div class="sale-btn__wrapper" v-if="Number(item.discount) > 0">
        <v-btn color="error" class="white--text" fab :ripple="false" dense>
          <!-- <div class="font-weight-bold" style="transform:rotate(-25deg)">
          -25%
        </div> -->
          <v-icon>mdi-sale</v-icon>
        </v-btn>
      </div>
      <div class="badge-overlay" v-if="+item.is_top_sell">
        <span class="top-right badge">{{$t("form.top_sell")}}</span>
      </div>
      <img
        v-if="!item.path_img"
        :src="require('@/assets/images/product-desc.png')"
        class="image-placeholder"
      />
      <v-img
        v-else
        class="card__image"
        :class="{ 'on-hover': hover }"
        :src="item.path_img"
        height="200"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center" style="min-height:60px">
            <v-progress-circular
              indeterminate
              :size="20"
              :width="2"
              color="green lighten-1"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <v-row align="center" class="mx-0 pb-0 position-relative" no-gutters>
        <v-card-text class="pb-0">
          <v-row align="center" no-gutters>
            <v-rating
              :value="Number(item.rating)"
              color="amber"
              background-color="deep-purple lighten-2"
              dense
              half-increments
              readonly
              size="14"
            ></v-rating>
            <div class="grey--text text-caption">
              {{ item.rating }}
            </div>
          </v-row>
        </v-card-text>
        <v-col cols="12">
          <v-card-title class="py-0">
            <span class="text-truncate">
              {{ item.name || "--" }}
            </span>
          </v-card-title>
        </v-col>

        <v-btn
          absolute
          color="deep-purple lighten-2"
          class="white--text"
          fab
          right
          top
          elevation="3"
          @click.stop="onAddItemInCart(item.uuid)"
          :loading="loading"
        >
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </v-row>
      <v-card-text>
        <div class="desc__wrapper text-truncate">
          {{ item.description || "--" }}
        </div>
        <div v-if="item?.options.length">
          <v-select
            outlined
            dense
            v-model="item.uuid_form_item_option"
            :items="item.options"
            :label="$t('supply.choose_option')"
            item-text="name"
            @click.stop
            item-value="uuid"
            color="deep-purple lightning-2"
            item-color="deep-purple lightning-2"
            hide-details="auto"
            :error-messages="noOptionsError"
            :menu-props="{ bottom: true, offsetY: true }"
          >
          </v-select>
        </div>
        <div v-else style="height:40px"></div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>
      <v-card-actions>
        <!-- uncommit later price with discount -->
        <div v-if="Number(item.discount) > 0">
          <v-row no-gutters class="pt-0">
            <span class="price px-2 font-weight-regular text-caption grey--text text-decoration-line-through">
              {{ getItemPrice(item) }} {{ $t("paymentPlan.uah") }}
            </span>
          </v-row>
          <v-row no-gutters>
            <span class="price px-2 font-weight-medium error--text">
              {{ item.price || 0 }} {{ $t("paymentPlan.uah") }}
            </span>
          </v-row>
        </div>
        <div v-else>
          <span class="price px-2 font-weight-medium" style="line-height:45px">
            {{ getItemPrice(item) }} {{ $t("paymentPlan.uah") }}
          </span>
        </div>
        <v-spacer />
        <span> </span>
        <v-btn plain class="text-transform-none" color="primary" @click="onClickDetails">
          {{ $t("form.more") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import EventBus from "@/events/EventBus";

export default {
  data: () => ({
    noOptionsError: ""
  }),
  mounted() {
    if (this.item.options.length) {
      this.item.options.forEach(item => {
        if (item.is_default === "1") {
          this.item.uuid_form_item_option = item.uuid;
        }
      });
      if(!this.item.uuid_form_item_option) this.item.uuid_form_item_option = this.item.options[0].uuid;
    }
  },
  props: {
    item: {
      require: true
    },
    loading: {
      require: false
    }
  },
  methods: {
    onClickDetails() {
      EventBus.$emit("on-chosen-details", this.item);
    },
    getItemPrice({ price, discount }) {
      const discountToNumber = Number(discount);
      const priceToNumber = Number(price);
      if (discountToNumber > 0) {
        return Math.floor(priceToNumber * ((100 + discountToNumber) / 100));
      } else {
        return priceToNumber || 0;
      }
    },
    onAddItemInCart() {
      if (this.item.options.length && !this.item.uuid_form_item_option) {
        this.noOptionsError = "Оберіть опцію";
        return;
      }
      EventBus.$emit("on-add-item-in-cart", this.item);
    }
  }
};
</script>

<style lang="scss" scoped>
.badge-overlay {
    position: absolute;
    left: 0%;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 3;
    -webkit-transition: width 1s ease, height 1s ease;
    -moz-transition: width 1s ease, height 1s ease;
    -o-transition: width 1s ease, height 1s ease;
    transition: width 0.4s ease, height 0.4s ease;
}
.badge {
    margin: 0;
    padding: 0;
    color: white;
    padding: 8px 0px;
    font-size: 15px;
    text-align: center;
    line-height: normal;
    background: #EF5350;
}

.badge::before, .badge::after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    width: 100%;
    height: 100%;
    background: inherit;
    min-width: 55px
}

.badge::before {
    right: 100%;
}

.badge::after {
    left: 100%
}
.top-right {
    position: absolute;
    top: 0;
    right: 0;
    -ms-transform: translateX(30%) translateY(0%) rotate(45deg);
    -webkit-transform: translateX(30%) translateY(0%) rotate(45deg);
    transform: translateX(30%) translateY(0%) rotate(45deg);
    -ms-transform-origin: top left;
    -webkit-transform-origin: top left;
    transform-origin: top left;
}
.card__image {
  transition: opacity 0.1s ease-in-out;
}

.card__image:not(.on-hover) {
  opacity: 0.8;
}
.image-placeholder {
  height: 200px;
  width: 100%;
  border-radius: 0;
  margin: 0;
}
.sale-btn {
  &__wrapper {
    position: absolute;
    left: -3%;
    top: -3%;
    z-index: 4;
  }
}
.desc {
  &__wrapper {
    min-height: 35px;
    max-height: 35px;
    height: 100%;
  }
}
</style>
